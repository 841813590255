import type { PropsWithChildren } from "react";
import clsx from "clsx";
import React, { useRef } from "react";
import { useLocation } from "react-router-dom";
import { IconBars } from "~/components/icons";
import Link from "~/components/link";

export type BrandProps = {
  to?: string;
  external?: boolean;
  label: React.ReactNode;
};
interface Props extends PropsWithChildren<unknown> {
  rightMenu?: React.ReactNode;
  brand?: BrandProps;
  rightId?: string;
  secondary?: boolean;
  tertiary?: boolean;
}

export default function NavBar({
  rightMenu,
  secondary,
  rightId = "right-menu-portal",
  brand,
  children
}: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const location = useLocation();
  // reset classList when location changes, but not on first mount
  React.useEffect(() => {
    if (ref.current) {
      ref.current.classList.add("collapse");
    }
  }, [location.pathname]);
  return (
    <nav
      className={clsx(
        "navbar navbar-static-top",
        secondary && "navbar-default sub-navbar",
        !secondary && "navbar-inverse",
        !secondary && import.meta.env.DEV && "navbar-inverse-dev"
      )}
    >
      <div className="navbar-header">
        <button
          type="button"
          className="navbar-toggle collapsed !mr-0 !border-white !py-2"
          onClick={() => {
            (ref?.current as HTMLDivElement).classList.toggle("collapse");
          }}
          data-toggle="collapse"
          aria-expanded="false"
        >
          <span className="sr-only">Toggle navigation</span>
          <IconBars className="text-white" />
        </button>
      </div>

      {brand && (
        <div className="navbar-header">
          {brand.to ? (
            <Link
              className="navbar-brand"
              to={brand.to}
              external={brand.external}
            >
              {brand.label}
            </Link>
          ) : (
            <span className="navbar-brand">{brand.label}</span>
          )}
        </div>
      )}
      <div
        ref={ref}
        className="navbar-collapse collapse"
        id={`navbar-${secondary ? "secondary" : "primary"}`}
      >
        <ul className="nav navbar-nav">{children}</ul>
        <ul
          className="nav navbar-nav navbar-right"
          id={secondary ? rightId || "right-menu-portal" : undefined}
        >
          {rightMenu}
        </ul>
      </div>
    </nav>
  );
}
